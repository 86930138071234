import React from 'react'
import './ExternalLinkButton.scss'

const ExternalLinkButton = ({ text, url, small }) => {
  return (
    <a
      className={`external-link-button ${small ? 'small' : ''}`}
      href={url}
      rel="noreferrer"
      target={'_blank'}
    >
      {text}
    </a>
  )
}

export default ExternalLinkButton
