import React from 'react'
import './Card.scss'

const Card = ({ title, text, children, published }) => {
  return (
    <div className={`card ${published ? 'news-card' : ''}`}>
      <div className="flex flex-col max-w-sm">
        {published ? (
          <>
            <time className="text-xs text-white opacity-50">
              Publicerad: {published}
            </time>
            {title && <h3 className="h4 mt-2 line-clamp-2">{title}</h3>}
            {text && (
              <div className={`text-peak `}>
                <div className={`max-w-lg  text-white opacity-70 line-clamp-2`}>
                  {text.replace(/<[^>]+>/g, '')}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <h4>{title}</h4>
            <p>{text}</p>
          </>
        )}
      </div>
      <div className="flex">{children}</div>
    </div>
  )
}

export default Card
