import React from 'react'
import './Hero.scss'

const Video = ({ videosrc }) => {
  return (
    <>
      <div className="video">
        <video
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          poster={'/poster.png'}
        >
          <source src={videosrc} type="video/mp4"></source>
        </video>
      </div>
      <div className="darken"></div>
    </>
  )
}

export default Video
