import { Link } from 'gatsby'
import React from 'react'
import './PrimaryLink.scss'

const PrimaryLink = ({ url, text }) => {
  const linkText = (text) => {
    switch (text) {
      case 'priser':
        return 'Se priser'
      case 'oppettider':
        return 'Våra öppettider'
      case 'shop':
        return 'Till shoppen'
      case 'nyheter':
        return 'Till nyheter'
      default:
        return text
    }
  }
  return (
    <Link className="primary-link" to={url} aria-label={linkText(text)}>
      {linkText(text)}
    </Link>
  )
}

export default PrimaryLink
