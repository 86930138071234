import React from 'react'
import UseSettings from '../../hooks/use-settings'
import ExternalLinkButton from '../Links/ExternalLinkButton'
import Video from './Video'
import Helmet from 'react-helmet'
import './Hero.scss'

const Hero = ({ title, videosrc, pretext }) => {
  const { settingsData } = UseSettings()
  const matchi = settingsData.wpPage.settings.matchi

  return (
    <div
      className="hero"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="100"
      data-sal-easing="ease"
    >
      <Helmet>
        <link rel="preconnect" href={videosrc} />
        <link rel="dns-prefetch" href={videosrc} />
      </Helmet>

      <Video videosrc={videosrc} />
      <div className="content">
        <div className="inner" style={{alignItems: 'center'}}>
          <div className="flex flex-col gap-1">
            <p className="sub">{pretext}</p>
            <h1>{title}</h1>
          </div>
          <div style={{display: 'grid', maxWidth: '300px'}}>
          <ExternalLinkButton text={'Boka Bana'} url={matchi} style={{margin: '20px'}}/>
          <ExternalLinkButton text={'Bli Medlem'} url={'https://www.matchi.se/facilities/membership/uapadel'} style={{margin: '20px'}}/>
          <ExternalLinkButton text={'Till Seriespel'} url={'https://www.matchi.se/forms/TCdNm3yx4gOVKJgeIzYQ?returnUrl=%2Ffacilities%2Fuapadel'} style={{margin: '20px'}}/>
          <ExternalLinkButton text={'Till Aktiviteter'} url={'https://www.matchi.se/facilities/uapadel#activities'} style={{margin: '20px'}}/>
          <ExternalLinkButton text={'Beat The Box'} url={'https://padelboard.app/club_ranking/67647'} style={{margin: '20px'}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
