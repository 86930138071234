import React, { useRef } from "react";
import Layout from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import Card from "../components/Card/Card";
import Grid from "../components/Grid/Grid";
import GridItem from "../components/Grid/GridItem";
import GridItemReversed from "../components/Grid/GridItemReversed";
import PrimaryLink from "../components/Links/PrimaryLink";
import ExternalLink from "../components/Links/ExternalLink";
import UseSettings from "../hooks/use-settings";
import { graphql, Link } from "gatsby";
import FluidImage from "../components/FluidImage/FluidImage";
import Seo from "../components/seo";
import { useIntersectionObserver } from "../helpers/helpers";

const ClientSideOnlyLazyMap = React.lazy(() => import("../components/Map/Map"));

const IndexPage = ({ data: { page, posts } }) => {
  const { settingsData } = UseSettings();
  const latitude = settingsData.wpPage.settings.koordinater.latitude;
  const longitude = settingsData.wpPage.settings.koordinater.longitude;
  const { titel, videosrc, heroPretext } = page.startsida;
  const cards = page.startsida.cards.card;
  const newsItems = posts?.edges;
  const grid = page.startsida.grid.gridItem;
  const mapSection = useRef(null);
  const isMapSectionVisible = useIntersectionObserver(mapSection);

  return (
    <Layout>
      <Seo title={titel} description={grid[1].text} />
      <Hero title={titel} pretext={heroPretext} videosrc={videosrc} />
      <div
        className="container"
        data-sal="fade"
        data-sal-duration="2000"
        data-sal-easing="ease"
      >
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 py-12">
          {cards.map((card, index) => (
            <Card key={index} title={card.title} text={card.text}>
              <PrimaryLink url={`/${card.slug}`} text={card.slug} />
            </Card>
          ))}
        </div>
      </div>

      <div ref={mapSection}>
        <Grid>
          <GridItem
            title={grid[0].title}
            text={grid[0].text}
            content={
              <>
                {isMapSectionVisible && (
                  <React.Suspense fallback={<div>Laddar karta</div>}>
                    <ClientSideOnlyLazyMap />
                  </React.Suspense>
                )}
              </>
            }
          >
            <ExternalLink
              url={`https://maps.google.com/?q=${latitude},${longitude}`}
              text={grid[0].linktext}
            />
          </GridItem>
          <GridItemReversed
            title={grid[1].title}
            text={grid[1].text}
            content={
              <FluidImage src={grid[1].image.localFile} title={grid[1].title} />
            }
          >
            <PrimaryLink url={`/${grid[1].slug}`} text={grid[1].linktext} />
          </GridItemReversed>
          <GridItem
            title={grid[2].title}
            text={grid[2].text}
            content={
              <FluidImage src={grid[2].image.localFile} title={grid[2].title} />
            }
          >
            <PrimaryLink url={`/${grid[2].slug}`} text={grid[2].linktext} />
          </GridItem>
          <GridItemReversed
            title={grid[3].title}
            text={grid[3].text}
            content={
              <FluidImage src={grid[3].image.localFile} title={grid[3].title} />
            }
          >
            <PrimaryLink url={`/${grid[3].slug}`} text={grid[3].linktext} />
          </GridItemReversed>
          <GridItem
            title={grid[4].title}
            text={grid[4].text}
            content={
              <FluidImage src={grid[4].image.localFile} title={grid[4].title} />
            }
          >
            <PrimaryLink url={`/${grid[4].slug}`} text={grid[4].linktext} />
          </GridItem>
        </Grid>
      </div>
      {newsItems?.length && (
        <>
          <div className="container text-center pt-12 lg:pt-16">
            <h2 className="h3 title">Senaste nytt</h2>
          </div>
          <div
            className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 py-8 lg:py-12 gap-6 "
            data-sal="fade"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            {newsItems.map((post, index) => (
              <Card
                key={index}
                title={post?.node?.title}
                text={post?.node?.nyhet?.text}
                published={post?.node?.date}
              >
                <Link to={`/nyheter/${post?.node?.slug}`}>Läs mer</Link>
              </Card>
            ))}
          </div>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query StartPage {
    posts: allWpPost(sort: { fields: date, order: DESC }, limit: 4) {
      edges {
        node {
          title
          slug
          date(formatString: "LL", locale: "sv")
          nyhet {
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    page: wpPage(title: { eq: "Startsida" }) {
      id
      startsida {
        videosrc
        heroPretext
        titel
        cards {
          card {
            slug
            text
            title
          }
        }
        grid {
          gridItem {
            title
            text
            slug
            linktext
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
