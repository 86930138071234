import React from 'react'
import './PrimaryLink.scss'

const ExternalLink = ({ url, text }) => {
  return (
    <a className="external-link" href={url} rel="noreferrer" target={'_blank'}>
      {text}
    </a>
  )
}

export default ExternalLink
